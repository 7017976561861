import React, { useRef, useState } from 'react';
import { Form, useClassNameMapper } from 'react-bootstrap';
import type { FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import ComponentHelper from '../../../helpers/ui/ComponentHelper/ComponentHelper';
import type { CorrectedOverlayTriggerRenderProps } from '../../../types/react-bootstrap/CorrectedOverlayTriggerRenderProps';
import { FormInputFieldInputType, FormInputFieldViewVariant } from '../enums';
import FormField from '../FormField/FormField';
import type { FormFieldProps } from '../types';
import { FieldType } from '../types';
import localStyles from './PasswordField.module.pcss';
import ShowHidePasswordToggle from './ShowHidePasswordToggle';
import type { PasswordFieldSpec } from './types';

interface PasswordFieldProps<NameT extends FieldPath<DataT>, DataT extends FieldValues>
  extends FormFieldProps<NameT, DataT, PasswordFieldSpec<NameT, DataT>> {
  /** Ref provided from OverlayTrigger to position the popover */
  overlayRef?: React.MutableRefObject<HTMLInputElement>;

  /** Methods provided from OverlayTrigger for making the popover appear/disappear */
  triggerHandler?: CorrectedOverlayTriggerRenderProps;
}

/**
 * Renders password field for the form
 *
 * @author Stephen McLaughry, Willi Hyde, Manish Shrestha
 */
const PasswordField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>({
  fieldSpec,
  formId,
  i18n,
  className,
  formMethods,
  onFormSubmit,
  submitOnChange,
  allFields,
  overlayRef,
  triggerHandler
}: PasswordFieldProps<NameT, DataT>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const {
    formState: { errors }
  } = formMethods;
  const { formatMessage, hasMessage, loading: textLoading } = i18n;
  const hasBeenFocused = useRef(false);
  const {
    name,
    inputType,
    attributes,
    viewVariant,
    focus,
    focusHandler,
    disabled,
    onKeyDown,
    onBlur: customOnBlur,
    onFocus: customOnFocus,
    onChange: customOnChange
  } = fieldSpec;
  const placeHolderKey = `${formId}.${name}.placeholder`;

  const [showPassword, setShowPassword] = useState(false);

  if (textLoading) {
    return null;
  }

  function getAdjustedInputType() {
    if (inputType === FormInputFieldInputType.VIEWABLE_PASSWORD) {
      return showPassword ? FormInputFieldInputType.TEXT : FormInputFieldInputType.PASSWORD;
    }

    return inputType;
  }

  function renderControl(
    register: Pick<UseFormRegisterReturn<NameT>, 'ref' | 'onBlur'>,
    onChange: (...event: unknown[]) => void
  ) {
    const { ref, onBlur } = register;

    return (
      <Form.Control
        aria-label={name}
        aria-describedby={name}
        name={name}
        as="input"
        className={cx('lia-input-field')}
        type={getAdjustedInputType()}
        placeholder={hasMessage(placeHolderKey) ? formatMessage(placeHolderKey) : undefined}
        ref={(element): void => {
          ref(element);
          if (focus) {
            ComponentHelper.focusHandler(hasBeenFocused, element, focusHandler);
          }

          if (overlayRef) {
            overlayRef.current = element;
          }
        }}
        isInvalid={errors[name] !== undefined}
        disabled={disabled}
        onKeyDown={onKeyDown ? event => onKeyDown(event, formMethods) : undefined}
        data-testid={`InputField.${formId}.${name}`}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event);

          if (customOnChange) {
            customOnChange(event);
          }
        }}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
          if (customOnFocus) {
            customOnFocus(event);
          }
          if (triggerHandler) {
            triggerHandler.onFocus(event);
          }
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          onBlur(event);

          if (customOnBlur) {
            customOnBlur(event, formMethods);
          }

          if (triggerHandler) {
            triggerHandler.onBlur(event);
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      />
    );
  }

  return (
    <FormField
      fieldSpec={fieldSpec}
      fieldType={FieldType.UNCONTROLLED}
      formId={formId}
      i18n={i18n}
      formMethods={formMethods}
      onFormSubmit={onFormSubmit}
      submitOnChange={submitOnChange}
      allFormFields={allFields}
      className={cx(
        {
          'lia-g-form-field-no-border': viewVariant === FormInputFieldViewVariant.NO_BORDER
        },
        className
      )}
    >
      {({ render: { register, onChange } }) => {
        return inputType === FormInputFieldInputType.VIEWABLE_PASSWORD ? (
          <div className={cx('lia-password-wrap')}>
            {renderControl(register, onChange)}
            <ShowHidePasswordToggle
              showPassword={showPassword}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        ) : (
          renderControl(register, onChange)
        );
      }}
    </FormField>
  );
};

export default PasswordField;
