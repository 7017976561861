import { FormFieldVariant } from '@aurora/shared-client/components/form/enums';
import { registerFormField } from '@aurora/shared-client/helpers/forms/FormFieldRegistrationHelper';
import dynamic from 'next/dynamic';

export default function formRegistry() {
  registerFormField(
    FormFieldVariant.ATTACHMENTS,
    dynamic(() => import('./AttachmentEditorField/AttachmentEditorField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.COVER_IMAGE_SUBJECT,
    dynamic(() => import('./CoverImageWithSubjectField/CoverImageWithSubjectField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.NODE_CHECKBOX_TREE,
    dynamic(() => import('./NodeCheckboxTreeField/NodeCheckboxTreeField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.RTE,
    dynamic(() => import('./RichTextEditorField/RichTextEditorField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.SEARCHABLE_SELECT,
    dynamic(() => import('./SearchableSelectField/SearchableSelectField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.MESSAGE_SEARCH,
    dynamic(() => import('./MessageSearchField/MessageSearchField'), { ssr: false })
  );
  registerFormField(
    FormFieldVariant.SEARCH_WITH_DROPDOWN,
    dynamic(() => import('./SearchWithDropDownMenuField/SearchWithDropDownMenuField'), {
      ssr: false
    })
  );
  registerFormField(
    FormFieldVariant.MULTI_NODE_PICKER,
    dynamic(() => import('./MultiNodePickerField/MultiNodePickerField'), {
      ssr: false
    })
  );
  registerFormField(
    FormFieldVariant.VIDEO_URL_WITH_PREVIEW,
    dynamic(() => import('./VideoUrlWithPreviewField/VideoUrlWithPreviewField'), {
      ssr: false
    })
  );
  registerFormField(
    FormFieldVariant.GUIDE_PICKER,
    dynamic(() => import('./GuideSelectorField/GuideSelectorField'), {
      ssr: false
    })
  );
}
