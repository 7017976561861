import type { ApolloClient } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client/cache';
import createApolloClient from '@aurora/shared-apollo/apolloClient';
import { getSwitchUserIdFromCookie } from '@aurora/shared-apollo/helpers/SwitchUserHelper';
import AppTypeContext from '@aurora/shared-client/components/context/AppTypeContext';
import AuthFlowContextProvider from '@aurora/shared-client/components/context/AuthFlowContext/AuthFlowContextProvider';
import IntlWrapperContextProvider from '@aurora/shared-client/components/context/IntlContext/IntlWrapperContextProvider';
import PagePathContext from '@aurora/shared-client/components/context/PagePathContext/PagePathContext';
import RedirectContext from '@aurora/shared-client/components/context/RedirectContext/RedirectContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import CachedThemeContextProvider from '@aurora/shared-client/components/context/ThemeContext/CachedThemeContextProvider/CachedThemeContextProvider';
import ToastContextProvider from '@aurora/shared-client/components/context/ToastContext/ToastContextProvider';
import ErrorBoundary from '@aurora/shared-client/components/error/ErrorBoundary/ErrorBoundary';
import Favicon from '@aurora/shared-client/components/Favicon/Favicon';
import useFoucFix from '@aurora/shared-client/components/useFoucFix';
import clientAuthenticationHelper from '@aurora/shared-client/helpers/authentication/ClientAuthenticationHelper';
import getPageContext from '@aurora/shared-client/helpers/NextContextHelper';
import '@aurora/shared-client/helpers/storage/LocalStoragePolyfill';
import reportMetric from '@aurora/shared-client/metrics/reportWebVitals';
import { getEndUserRoutes } from '@aurora/shared-client/routes/endUserRoutes';
import globalStyles from '@aurora/shared-client/styles/styles.module.pcss';
import '@aurora/shared-client/styles/unscoped.pcss';
import { APP_ID_ATTRIBUTE_PREFIX, AppType } from '@aurora/shared-types/app';
import type { TokenResult } from '@aurora/shared-types/authentication';
import type LogoutType from '@aurora/shared-types/authentication/enums';
import type { PageContext, RouteInfo } from '@aurora/shared-types/community';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { Tenant } from '@aurora/shared-types/tenant';
import ContextCookieHelper from '@aurora/shared-utils/authentication/ContextCookieHelper';
import { getBranchNameFromCookie } from '@aurora/shared-utils/helpers/developer/SwitchBranchCookieHelper';
import { getLog } from '@aurora/shared-utils/log';
import { canUseDOM } from 'exenv';
import type { NextPageContext } from 'next';
import type { AppContext, AppInitialProps, AppProps, NextWebVitalsMetric } from 'next/app';
import App from 'next/app';
import type { NextRouter } from 'next/dist/shared/lib/router/router';
import dynamic from 'next/dynamic';
// eslint-disable-next-line no-restricted-imports
import { withRouter } from 'next/router';
import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { UIDReset } from 'react-uid';
import appConfig from '../apollo/appConfig';
import SsoRegistrationContextProvider from '../components/authentication/SsoRegistrationContextProvider/SsoRegistrationContextProvider';
import SwitchUserContextProvider from '../components/authentication/SwitchUserContextProvider/SwitchUserContextProvider';
import AppContextProvider from '../components/context/AppContext/AppContextProvider/AppContextProvider';
import EmailVerificationContextProvider from '../components/context/EmailVerificationContext/EmailVerificationContextProvider';
import SwitchBranchContextProvider from '../components/developer/SwitchBranchContextProvider/SwitchBranchContextProvider';
import formRegistry from '../components/form/FormRegistry';
import ToggleTextKeysContextProvider from '../components/i18n/ToggleTextKeysContextProvider/ToggleTextKeysContextProvider';
import usePreviousRouteInfo from '../components/usePreviousRouteInfo';
import endUserStaticAssetVersions from '../generated/static-hashes.json';

const PageErrorBoundary = dynamic(
  () => import('@aurora/shared-client/components/error/PageError/PageErrorBoundary')
);

const log = getLog(module);

formRegistry();

let localApolloClient: ApolloClient<NormalizedCacheObject>;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param context
 * @param tenant
 * @param authToken
 * @param referrer
 * @param getRouteInfo
 * @param router
 * @param initialState {Object}
 */
function initApolloClient(
  context: NextPageContext | null,
  tenant: Tenant,
  authToken: TokenResult,
  referrer?: string,
  getRouteInfo?: () => Promise<RouteInfo>,
  router?: NextRouter,
  initialState?: NormalizedCacheObject | null | undefined
): ApolloClient<NormalizedCacheObject> {
  if (typeof window === 'undefined') {
    if (!tenant) {
      throw new Error('GraphQL operations through Apollo require a tenant');
    }

    return createApolloClient(
      appConfig,
      tenant,
      authToken,
      async () => {
        const ServerAuthenticationHelper = await import(
          '@aurora/shared-server/helpers/authentication/ServerAuthenticationHelper'
        );
        const serverAuthenticationHelper = ServerAuthenticationHelper.fromNextPageContext(
          context,
          appConfig
        );
        return serverAuthenticationHelper.getTokenResult();
      },
      async (type: LogoutType | null) => {
        const ServerAuthenticationHelper = await import(
          '@aurora/shared-server/helpers/authentication/ServerAuthenticationHelper'
        );
        const serverAuthenticationHelper = ServerAuthenticationHelper.fromNextPageContext(
          context,
          appConfig
        );
        return serverAuthenticationHelper.logout(type);
      },
      getRouteInfo,
      referrer,
      router,
      initialState
    );
  }

  // Reuse client on the client-side
  if (!localApolloClient) {
    if (!tenant) {
      throw new Error('GraphQL operations through Apollo require a tenant');
    }

    localApolloClient = createApolloClient(
      appConfig,
      tenant,
      authToken,
      () => {
        return clientAuthenticationHelper.getTokenResult();
      },
      (type?: LogoutType | null) => {
        return clientAuthenticationHelper.logout(type);
      },
      getRouteInfo,
      referrer,
      router,
      initialState
    );
  }

  return localApolloClient;
}

interface EndUserPageProps extends PageContext<EndUserPages>, NextPageContext {
  apolloState: NormalizedCacheObject | null | undefined;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  timeZone: string | null;
  switchUserId: number | null;
  referrer: string | null;
  acceptLanguage: string | null;
  setRedirect: (redirectUrl: string, statusCode: number) => void;
}

/**
 * Override of the NextJs <App> with i18n support using react-intl and multi-tenancy support.
 *
 * @author Adam Ayres, Dolan Halbrook
 */
function EndUserApp({ Component, pageProps, router }: AppProps<EndUserPageProps>) {
  const {
    locale = 'en-US',
    acceptLanguage,
    timeZone,
    tenant,
    apolloClient,
    apolloState,
    authToken,
    switchUserId,
    branchName,
    routeInfo,
    isCrawler,
    referrer,
    setRedirect
  } = pageProps;

  const { path } = routeInfo;

  /**
   * LIA-94494 There is a core NextJs bug that can cause dynamically loaded components
   * to have their CSS unloaded too early. This hook prevents attempts to prevent that from happening.
   *
   * Core NextJs bug: https://github.com/vercel/next.js/issues/17464
   * Fix copied from:
   *   https://github.com/vercel/next.js/issues/17464#issuecomment-1376391861
   *   https://github.com/moxystudio/next-with-moxy/blob/master/www/app/use-fouc-fix.js
   */
  useFoucFix();

  // calling to store previous route information on every page change
  usePreviousRouteInfo(routeInfo);

  const client =
    apolloClient ||
    initApolloClient(null, tenant, authToken, referrer, async () => routeInfo, router, apolloState);

  function parseLocales(data: string) {
    return data
      ? data.split(',').map(item => {
          const [language] = item.split(';');
          return new Intl.Locale(language);
        })
      : [];
  }

  return (
    <>
      <ErrorBoundary
        fallbackComponent={() => (
          <PageErrorBoundary statusCode={500} tenant={tenant} apolloClient={client} />
        )}
      >
        <UIDReset prefix={APP_ID_ATTRIBUTE_PREFIX}>
          <AppTypeContext.Provider value={AppType.END_USER}>
            <PagePathContext.Provider value={path}>
              <TenantContext.Provider key={tenant?.id} value={tenant}>
                <ApolloProvider client={client}>
                  <IntlWrapperContextProvider
                    locale={locale}
                    timeZone={timeZone}
                    acceptLanguage={parseLocales(acceptLanguage)}
                  >
                    <ThemeProvider classNameMap={globalStyles}>
                      <RedirectContext.Provider value={{ setRedirect }}>
                        <ToastContextProvider>
                          <SwitchBranchContextProvider branchName={branchName}>
                            <SwitchUserContextProvider switchUserIdInit={switchUserId}>
                              <AppContextProvider isCrawler={isCrawler}>
                                <EmailVerificationContextProvider>
                                  <CachedThemeContextProvider>
                                    <Favicon version={endUserStaticAssetVersions.favicons} />
                                    <SsoRegistrationContextProvider>
                                      <AuthFlowContextProvider>
                                        <ToggleTextKeysContextProvider>
                                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                          <Component {...pageProps} />
                                        </ToggleTextKeysContextProvider>
                                      </AuthFlowContextProvider>
                                    </SsoRegistrationContextProvider>
                                  </CachedThemeContextProvider>
                                </EmailVerificationContextProvider>
                              </AppContextProvider>
                            </SwitchUserContextProvider>
                          </SwitchBranchContextProvider>
                        </ToastContextProvider>
                      </RedirectContext.Provider>
                    </ThemeProvider>
                  </IntlWrapperContextProvider>
                </ApolloProvider>
              </TenantContext.Provider>
            </PagePathContext.Provider>
          </AppTypeContext.Provider>
        </UIDReset>
      </ErrorBoundary>
    </>
  );
}

EndUserApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
  const { ctx } = appContext;
  const { AppTree, res } = ctx;

  function setRedirect(redirectUrl: string, statusCode: number = 302) {
    if (redirectUrl && res && !res.writableEnded) {
      res.statusCode = statusCode ?? 302;
      res.setHeader('Location', redirectUrl);
      res.end();
    }
  }

  const pageContext = getPageContext(ctx);
  const {
    locale,
    acceptLanguage,
    timeZone,
    tenant,
    csrfToken,
    getCsrfToken,
    authToken,
    switchUserId,
    branchName,
    routeInfo,
    isCrawler,
    referrer,
    pageRoutes,
    communityPropertiesCache
  } = pageContext;

  log.debug('Page context is %O', pageContext);

  const finalReferrer = referrer ?? ctx?.req?.headers?.referer;
  const currentPath = canUseDOM ? ctx.asPath : routeInfo?.path;
  const initializedApolloClient = initApolloClient(
    ctx,
    tenant,
    authToken,
    finalReferrer,
    async () => ({
      pageName: routeInfo.pageName,
      path: currentPath
    }),
    null,
    communityPropertiesCache?.getCommunityPublicPropertiesCacheObject() ?? {}
  );
  const finalAcceptLanguage = acceptLanguage ?? ctx?.req?.headers?.['accept-language'];

  const routes = pageRoutes ?? (await getEndUserRoutes(tenant, initializedApolloClient));

  const currentRoute = currentPath ? routes.getRouteByPath(currentPath) : null;
  const currentPageName = currentRoute ? currentRoute?.name ?? null : null;

  const appProps = await App.getInitialProps(appContext);
  const finalSwitchUserId = switchUserId ?? getSwitchUserIdFromCookie(ctx?.req?.headers?.cookie);
  const finalBranchName = branchName ?? getBranchNameFromCookie(ctx?.req?.headers?.cookie);

  const pageRouteInfo = {
    path: currentPath,
    pageName: currentPageName
  };

  const { pageProps } = appProps;

  Object.assign(pageProps, {
    locale,
    acceptLanguage: finalAcceptLanguage,
    timeZone,
    tenant,
    routeInfo: pageRouteInfo,
    csrfToken: getCsrfToken ? getCsrfToken() : csrfToken,
    authToken,
    switchUserId: finalSwitchUserId,
    branchName: finalBranchName,
    isCrawler,
    referrer: finalReferrer,
    setRedirect
  } as EndUserPageProps);

  log.debug('App props are %O', appProps);

  if (typeof window === 'undefined' && process.env.SSR_DATA_FETCH_ENABLED === 'true') {
    if (res && (res.writableEnded || currentPageName == null)) {
      return appProps;
    }
    try {
      const now = performance.now();

      // Run all GraphQL queries
      const { getDataFromTree } = await import('@apollo/client/react/ssr');

      await getDataFromTree(
        <AppTree
          pageProps={
            {
              ...pageProps,
              apolloClient: initializedApolloClient
            } as EndUserPageProps
          }
        />
      );

      log.trace('All GraphQL queries resolved in %dms', performance.now() - now);
      if (res) {
        ContextCookieHelper.setResponseCookies(res);
      }
    } catch (error) {
      // Prevent Apollo Client GraphQL errors from crashing SSR.
      // Handle them in components via the data.error prop:
      // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
      log.error(error, "Error while running 'getDataFromTree'");
      return appProps;
    }
  }

  // Extract query data from the Apollo store
  pageProps.apolloState = initializedApolloClient.cache.extract();

  return appProps;
};

/**
 * Log out web vitals info.
 * @param nextMetric
 */
export function reportWebVitals(nextMetric: NextWebVitalsMetric) {
  if (nextMetric.label === 'web-vital') {
    // For web vitals add current route (URL) and pageName to the information being logged on browser console
    const { routeInfo } = getPageContext();
    const metricBody = {
      ...nextMetric,
      pageName: routeInfo?.pageName,
      route: window.location.href,
      source: appConfig.appId
    };
    reportMetric(metricBody);
  }
}

export default withRouter(EndUserApp);
