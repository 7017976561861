import type { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for the featured content widget
 *
 * @author Jonathan Bridges
 */
export class FeaturedWidgetFeature implements CommunityFeature<Feature.FEATURED_WIDGET> {
  getFeature(): Feature.FEATURED_WIDGET {
    return Feature.FEATURED_WIDGET;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.FEATURED_CONTENT_WIDGET,
        component: dynamic(
          () => import('../components/featured/content/FeaturedContentWidget/FeaturedContentWidget')
        ),
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.IdeaBoardPage,
          EndUserPages.ForumMessagePage,
          EndUserPages.BlogBoardPage,
          EndUserPages.BlogMessagePage,
          EndUserPages.TkbBoardPage,
          EndUserPages.TkbMessagePage,
          EndUserPages.UserPage,
          EndUserPages.EventBoardPage,
          EndUserPages.IdeaMessagePage
        ],
        isInstanceIdRequired: true,
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/featured/content/FeaturedContentWidgetEditor/FeaturedContentWidgetEditor'
              )
          ),
          removeModal: dynamic(
            () =>
              import(
                '../components/featured/content/FeaturedContentWidgetRemovalModal/FeaturedContentWidgetRemovalModal'
              )
          )
        }
      },
      {
        id: EndUserComponent.FEATURED_PLACES_WIDGET,
        component: dynamic(
          () => import('../components/featured/places/FeaturedPlacesWidget/FeaturedPlacesWidget')
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/featured/places/FeaturedPlacesWidgetEditor/FeaturedPlacesWidgetEditor'
              )
          ),
          removeModal: dynamic(
            () =>
              import(
                '../components/featured/places/FeaturedPlacesWidgetRemovalModal/FeaturedPlacesWidgetRemovalModal'
              )
          )
        },
        allowedPages: [
          EndUserPages.CommunityPage,
          EndUserPages.CategoryPage,
          EndUserPages.GroupHubPage,
          EndUserPages.ForumBoardPage,
          EndUserPages.IdeaBoardPage,
          EndUserPages.IdeaMessagePage,
          EndUserPages.ForumMessagePage,
          EndUserPages.BlogBoardPage,
          EndUserPages.BlogMessagePage,
          EndUserPages.TkbBoardPage,
          EndUserPages.TkbMessagePage,
          EndUserPages.UserPage,
          EndUserPages.EventBoardPage
        ],
        isInstanceIdRequired: true
      },
      {
        id: EndUserComponent.FEATURED_IDEA_STATUSES_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/featured/ideaStatuses/FeaturedIdeaStatusesWidget/FeaturedIdeaStatusesWidget'
            )
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/featured/ideaStatuses/FeaturedIdeaStatusesWidgetEditor/FeaturedIdeaStatusesWidgetEditor'
              )
          ),
          removeModal: dynamic(
            () =>
              import(
                '../components/featured/ideaStatuses/FeaturedIdeaStatusesWidgetRemovalModal/FeaturedIdeaStatusesWidgetRemovalModal'
              )
          )
        },
        allowedPages: [EndUserPages.IdeaBoardPage],
        isInstanceIdRequired: true
      }
    ];
  }
}
