const extensionToMimeType: Record<string, string> = {
  '*.png': 'image/png',
  '*.jpg': 'image/jpeg',
  '*.jpeg': 'image/jpeg',
  '*.gif': 'image/gif',
  '*.svg': 'image/svg+xml',
  '*.tiff': 'image/tiff',
  '*.bmp': 'image/bmp',
  '*.psd': 'image/vnd.adobe.photoshop'
};

const extensionToCustomMimeType: Record<string, string> = {
  story: 'application/story',
  swu: 'application/swu'
};

export function getMimeTypeForExtension(extension: string) {
  return extensionToMimeType[extension.toLowerCase()];
}

export function getCustomMimeTypeForExtension(extension: string) {
  return extensionToCustomMimeType[extension.toLowerCase()];
}
