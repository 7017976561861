import dynamic from 'next/dynamic';
import type React from 'react';
import type { FieldValues } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path/';
import { FormFieldVariant } from '../../components/form/enums';
import NewPasswordField from '../../components/form/NewPasswordField/NewPasswordField';
import type { FormFieldProps, FormFieldType } from '../../components/form/types';

export type FormFieldT<
  FieldVariantT extends FormFieldVariant,
  FieldSpecT extends FormFieldType<FieldPath<FieldValues>, FieldValues, FieldVariantT>
> = React.ComponentType<
  React.PropsWithChildren<FormFieldProps<FieldPath<FieldValues>, FieldValues, FieldSpecT>>
>;

const AsyncSearchableSelectField = dynamic(
  () => import('../../components/form/AsyncSearchableSelectField/AsyncSearchableSelectField')
);
const AvatarEditorField = dynamic(
  () => import('../../components/form/AvatarEditorField/AvatarEditorField')
);
const LanguageSelectionField = dynamic(
  () => import('../../components/form/LanguageSelectionField/LanguageSelectionField')
);
const BackgroundImageWithPropertiesField = dynamic(
  () =>
    import(
      '../../components/form/BackgroundImageWithPropertiesField/BackgroundImageWithPropertiesField'
    )
);
const BoundaryPaddingField = dynamic(
  () => import('../../components/form/BoundaryPaddingField/BoundaryPaddingField')
);
const ColorSwatchPickerField = dynamic(
  () => import('../../components/form/ColorSwatchPickerField/ColorSwatchPickerField')
);
const CheckField = dynamic(() => import('../../components/form/CheckField/CheckField'));
const CheckWithTextField = dynamic(
  () => import('../../components/form/CheckWithTextField/CheckWithTextField')
);
const NodePickerField = dynamic(
  () => import('../../components/form/NodePickerField/NodePickerField')
);
const CreatableSingleSelectField = dynamic(
  () => import('../../components/form/CreatableSingleSelectField/CreatableSingleSelectField')
);
const ContentWrapperField = dynamic(
  () => import('../../components/form/ContentWrapperField/ContentWrapperField')
);
const CustomField = dynamic(() => import('../../components/form/CustomField/CustomField'));
const DateTimeEditorField = dynamic(
  () => import('../../components/form/DateTimeEditorField/DateTimeEditorField')
);
const DetailedSelectField = dynamic(
  () => import('../../components/form/DetailedSelectField/DetailedSelectField')
);
const DropdownSelectField = dynamic(
  () => import('../../components/form/DropdownSelectField/DropdownSelectField')
);

const GroupedDropdownSelectField = dynamic(
  () => import('../../components/form/GroupedDropDownSelectField/GroupedDropDownSelectField')
);

const DraggableMultiSelectFormField = dynamic(
  () => import('../../components/form/DraggableMultiSelectFormField/DraggableMultiSelectFormField')
);
const DateTimeRangeSelectorField = dynamic(
  () => import('../../components/form/DateTimeRangeSelectorField/DateTimeRangeSelectorField')
);
const FileField = dynamic(() => import('../../components/form/FileField/FileField'));
const GroupHubDiscussionStylesField = dynamic(
  () => import('../../components/form/GroupHubDiscussionStylesField/GroupHubDiscussionStylesField')
);
const IconRadioField = dynamic(() => import('../../components/form/IconRadioField/IconRadioField'));
const ImageUploadField = dynamic(
  () => import('../../components/form/ImageUploadField/ImageUploadField')
);
const InputField = dynamic(() => import('../../components/form/InputField/InputField'));
const InputGroupField = dynamic(
  () => import('../../components/form/InputGroupField/InputGroupField')
);
const LocationField = dynamic(() => import('../../components/form/LocationField/LocationField'));
const MultiCheckField = dynamic(
  () => import('../../components/form/MultiCheckField/MultiCheckField')
);
const MultiSelectField = dynamic(
  () => import('../../components/form/MultiSelectField/MultiSelectField')
);
const MultiImageUploadField = dynamic(
  () => import('../../components/form/MultiImageUploadField/MultiImageUploadField')
);
const PasswordField = dynamic(() => import('../../components/form/PasswordField/PasswordField'));
const PlaceholderFormField = dynamic(
  () => import('../../components/form/PlaceholderFormField/PlaceholderFormField')
);
const PillRadioField = dynamic(() => import('../../components/form/PillRadioField/PillRadioField'));
const RadioField = dynamic(() => import('../../components/form/RadioField/RadioField'));
const RadioWithCustomInputField = dynamic(
  () => import('../../components/form/RadioWithCustomInputField/RadioWithCustomInputField')
);
const RangeField = dynamic(() => import('../../components/form/RangeField/RangeField'));
const SearchField = dynamic(() => import('../../components/form/SearchField/SearchField'));
const SelectField = dynamic(() => import('../../components/form/SelectField/SelectField'));
const TextAreaField = dynamic(() => import('../../components/form/TextAreaField/TextAreaField'));
const TagEditorField = dynamic(() => import('../../components/form/TagEditorField/TagEditorField'));
const DateInputField = dynamic(() => import('../../components/form/DateInputField/DateInputField'));
const TooltipColorPickerField = dynamic(
  () => import('../../components/form/TooltipColorPickerField/TooltipColorPickerField')
);
const UserPickerField = dynamic(
  () => import('../../components/form/UserPickerField/UserPickerField')
);
const CreatableMultiSelectField = dynamic(
  () => import('../../components/form/CreatableMultiSelectField/CreatableMultiSelectField')
);

export const registeredFields: Map<
  FieldPath<FieldValues>,
  FormFieldT<FormFieldVariant, FormFieldType<FieldPath<FieldValues>, FieldValues, FormFieldVariant>>
> = new Map<
  FieldPath<FieldValues>,
  FormFieldT<FormFieldVariant, FormFieldType<FieldPath<FieldValues>, FieldValues, FormFieldVariant>>
>();

export function registerFormField<
  FieldVariantT extends FormFieldVariant,
  FieldSpecT extends FormFieldType<FieldPath<FieldValues>, FieldValues, FieldVariantT>
>(fieldVariant: FieldVariantT, field: FormFieldT<FieldVariantT, FieldSpecT>) {
  if (!registeredFields.has(fieldVariant)) {
    registeredFields.set(
      fieldVariant as string,
      field as FormFieldT<
        FieldVariantT,
        FormFieldType<FieldPath<FieldValues>, FieldValues, FieldVariantT>
      >
    );
  } else {
    throw new Error(`Field with field variant ${fieldVariant} already exists.`);
  }
}

registerFormField(FormFieldVariant.ASYNC_SEARCHABLE_SELECT, AsyncSearchableSelectField);
registerFormField(FormFieldVariant.AVATAR_EDITOR, AvatarEditorField);
registerFormField(FormFieldVariant.BACKGROUND_IMAGE_PROPERTIES, BackgroundImageWithPropertiesField);
registerFormField(FormFieldVariant.BOUNDARY_PADDING, BoundaryPaddingField);
registerFormField(FormFieldVariant.CHECK, CheckField);
registerFormField(FormFieldVariant.CHECK_WITH_TEXT_FIELD, CheckWithTextField);
registerFormField(FormFieldVariant.COLOR_SWATCH, ColorSwatchPickerField);
registerFormField(FormFieldVariant.CONTENT_WRAPPER, ContentWrapperField);
registerFormField(FormFieldVariant.CUSTOM_FIELD, CustomField);
registerFormField(FormFieldVariant.DATETIME, DateTimeEditorField);
registerFormField(FormFieldVariant.DETAILED_SELECT, DetailedSelectField);
registerFormField(FormFieldVariant.DROPDOWN_SELECT, DropdownSelectField);
registerFormField(FormFieldVariant.FILE, FileField);
registerFormField(FormFieldVariant.GROUP_HUB_DISCUSSION_STYLES, GroupHubDiscussionStylesField);
registerFormField(FormFieldVariant.ICON_RADIO, IconRadioField);
registerFormField(FormFieldVariant.IMAGE_UPLOAD, ImageUploadField);
registerFormField(FormFieldVariant.INPUT, InputField);
registerFormField(FormFieldVariant.INPUT_GROUP, InputGroupField);
registerFormField(FormFieldVariant.LOCATION, LocationField);
registerFormField(FormFieldVariant.LANGUAGE_SELECTION_FIELD, LanguageSelectionField);
registerFormField(FormFieldVariant.MULTI_CHECK_BOX, MultiCheckField);
registerFormField(FormFieldVariant.MULTI_IMAGE_UPLOAD, MultiImageUploadField);
registerFormField(FormFieldVariant.MULTI_SELECT, MultiSelectField);
registerFormField(FormFieldVariant.NEW_PASSWORD, NewPasswordField);
registerFormField(FormFieldVariant.PASSWORD, PasswordField);
registerFormField(FormFieldVariant.PILL_RADIO, PillRadioField);
registerFormField(FormFieldVariant.PLACEHOLDER_FORM_FIELD, PlaceholderFormField);
registerFormField(FormFieldVariant.RADIO, RadioField);
registerFormField(FormFieldVariant.RADIO_WITH_CUSTOM_INPUT, RadioWithCustomInputField);
registerFormField(FormFieldVariant.RANGE, RangeField);
registerFormField(FormFieldVariant.SEARCH, SearchField);
registerFormField(FormFieldVariant.SELECT, SelectField);
registerFormField(FormFieldVariant.TEXT_AREA, TextAreaField);
registerFormField(FormFieldVariant.COLOR_PICKER, TooltipColorPickerField);
registerFormField(FormFieldVariant.CREATABLE_SINGLE_SELECT, CreatableSingleSelectField);
registerFormField(FormFieldVariant.NODE_PICKER, NodePickerField);
registerFormField(FormFieldVariant.TAGS, TagEditorField);
registerFormField(FormFieldVariant.CREATABLE_MULTI_SELECT, CreatableMultiSelectField);
registerFormField(FormFieldVariant.DATE_TIME_RANGE_SELECT, DateTimeRangeSelectorField);
registerFormField(FormFieldVariant.DATE_INPUT, DateInputField);
registerFormField(FormFieldVariant.GROUPED_DROPDOWN_SELECT, GroupedDropdownSelectField);
registerFormField(FormFieldVariant.DRAGGABLE_MUTLI_SELECT_FIELD, DraggableMultiSelectFormField);
registerFormField(FormFieldVariant.USER_PICKER, UserPickerField);
