import React from 'react';
import type { PropsWithChildren } from 'react';
import type { PageAccessData } from '../../../usePageAccess';
import usePageParamValidationRedirect from '../../../usePageParamValidationRedirect';

const RedirectionWrapper: React.FC<PropsWithChildren<{ pageParams: PageAccessData }>> = ({
  pageParams,
  children
}) => {
  /**
   * Validates URL params and redirects in instances, such as a valid board id is present in the URL, but with an
   * invalid parent category id
   */
  usePageParamValidationRedirect(pageParams);
  return <>{children}</>;
};

export default RedirectionWrapper;
