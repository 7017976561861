import ApolloErrorAlert from '@aurora/shared-client/components/common/ApolloErrorAlert/ApolloErrorAlert';
import { LoadingVariant } from '@aurora/shared-client/components/common/Loading/enums';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '@aurora/shared-client/components/common/Loading/types';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import CachedThemeContextProvider from '@aurora/shared-client/components/context/ThemeContext/CachedThemeContextProvider/CachedThemeContextProvider';
import PageError from '@aurora/shared-client/components/error/PageError/PageError';
import useLoginRedirect from '@aurora/shared-client/components/useLoginRedirect';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  MessagePagesAndParams,
  UserPageAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import type { PageContext } from '@aurora/shared-generated/types/graphql-schema-types';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextCommunityFragment } from '@aurora/shared-generated/types/graphql-types';
import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { EndUserComponent, EndUserPathParams } from '@aurora/shared-types/pages/enums';
import {
  AccessFunctionType,
  RestrictedPageBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import type { FormatMessageKey, FormatMessageValues } from '@aurora/shared-types/texts';
import { DEFAULT_THEME_ID } from '@aurora/shared-utils/helpers/theme/ThemeConstant';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import { getLog } from '@aurora/shared-utils/log';
import { canUseDOM } from 'exenv';
import React, { useCallback, useContext } from 'react';
import type {
  AppContextQuery,
  AppContextQueryVariables,
  ContextNodeFragment,
  ContextNodeQuery,
  ContextNodeQueryVariables,
  ContextUserQuery,
  ContextUserQueryVariables
} from '../../../../types/graphql-types';
import useEndUserPageDescriptor from '../../../useEndUserPageDescriptor';
import usePageAccess from '../../../usePageAccess';
import useTranslation from '../../../useTranslation';
import useContextObjectFromUrl, {
  getNodeIdFromUrl,
  UrlObject
} from '../../useContextObjectFromUrl';
import appContextQuery from './AppContext.query.graphql';
import useAppContextProviderMessage from './useAppContextProviderMessage';
import RedirectionWrapper from './RedirectionWrapper';

const log = getLog(module);

interface Props {
  /**
   * Is the request from a search crawler?
   */
  isCrawler?: boolean;
}

/**
 * Put aurora-specific data in the Aurora context.
 *
 * @author Dolan Halbrook, Adam Ayres
 */
const AppContextProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, isCrawler }) => {
  const tenant = useContext(TenantContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.APP_CONTEXT_PROVIDER
  );
  const { router } = useEndUserRoutes();
  const {
    privateCommunityAnonAllowed,
    redirectBehavior: {
      pageAccessResolver: { resolverFunctionType },
      restrictedPageBehavior
    }
  } = useEndUserPageDescriptor();
  const {
    publicConfig: { quiltsV2Enabled }
  } = tenant;

  const skipPolicies: boolean =
    restrictedPageBehavior !== RestrictedPageBehavior.LOCALIZED_CATEGORIES_PAGE &&
    resolverFunctionType !== AccessFunctionType.NOTIFICATIONS_PAGE &&
    resolverFunctionType !== AccessFunctionType.CASE_PORTAL &&
    resolverFunctionType !== AccessFunctionType.INBOX_PAGE &&
    resolverFunctionType !== AccessFunctionType.CREATE_GROUP_HUB_PAGE;

  // Aurora context
  const appContextQueryResult = useQueryWithTracing<AppContextQuery, AppContextQueryVariables>(
    module,
    appContextQuery,
    {
      variables: {
        quiltV2: quiltsV2Enabled,
        useCommunityPolicies: !skipPolicies
      }
    }
  );
  const {
    data: appContextData,
    loading: appContextLoading,
    error: appContextError
  } = appContextQueryResult;

  // Node from URL
  const urlNodeQueryResult = useContextObjectFromUrl<ContextNodeQuery, ContextNodeQueryVariables>(
    module,
    UrlObject.NODE
  );
  const { data: urlNodeData, error: urlNodeError, loading: urlNodeLoading } = urlNodeQueryResult;

  // Message from URL
  const urlMessageQueryResult = useAppContextProviderMessage(module);
  const {
    data: urlMessageData,
    loading: urlMessageLoading,
    error: urlMessageError
  } = urlMessageQueryResult;

  // User from URL
  const urlUserQueryResult = useContextObjectFromUrl<ContextUserQuery, ContextUserQueryVariables>(
    module,
    UrlObject.USER
  );
  const { data: urlUserData, error: urlUserError, loading: urlUserLoading } = urlUserQueryResult;

  const isLoading =
    (canUseDOM && localStorage?.getItem(LocalStorageKeys.AUTH_INVALIDATING_KEY) != null) ||
    (!appContextData && appContextLoading) ||
    (!urlNodeData && urlNodeLoading) ||
    (!urlMessageData && urlMessageLoading) ||
    (!urlUserData && urlUserLoading) ||
    textLoading;

  const { canAccess, canRegister, self } = appContextData ?? {};
  const isAnonymous = self?.registrationData?.status === RegistrationStatus.Anonymous;

  const canAccessCommunity = useCallback(() => {
    if (isLoading) {
      return true;
    }

    if (canAccess === false && isAnonymous === true) {
      return false;
    }

    if (appContextError) {
      /**
       * Check if any of the GraphQL errors are from the user being `UNAUTHENTICATED`.
       * In which case we know the community is set to private and only accessibly by
       * authenticated users.
       */
      return !appContextError.graphQLErrors.some(
        item => item?.extensions?.code === 'ANONYMOUS_ACCESS_NOT_ALLOWED'
      );
    }
    return true;
  }, [canAccess, appContextError, isAnonymous, isLoading]);

  const communityId = appContextData?.community?.id;

  /**
   * When the community is set to private and the user is unauthenticated, then we redirect them to the login page
   */
  const shouldLoginRedirect: boolean = !canAccessCommunity() && !privateCommunityAnonAllowed;
  useLoginRedirect(shouldLoginRedirect);

  const pageParamData = {
    appContextQueryResult: appContextQueryResult,
    contextNodeQueryResult: urlNodeQueryResult,
    contextMessageQueryResult: urlMessageQueryResult,
    contextUserQueryResult: urlUserQueryResult
  };
  /**
   * Protected node pages redirect the user to the community page with an alert. If the user is anonymous, they are first
   * redirected to the login page
   */
  const { loading: pageAccessLoading } = usePageAccess(pageParamData, isAnonymous, communityId);

  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: LoadingSize.LG,
      spacing: LoadingSpacing.XL
    }
  };

  // if in loading state or redirecting to a login screen, bail out
  if (isLoading || shouldLoginRedirect || pageAccessLoading) {
    return <Loading variant={loadingVariant} />;
  }

  const {
    componentsLastModified,
    pagesLastModified,
    pageScriptsLastModified,
    quiltLastModified,
    textLastModified,
    themeLastModified,
    quiltWrapperLastModified,
    entityDefinitionsLastModified,
    fieldDefinitionsLastModified,
    theme
  } = appContextData ?? {};

  function getCommunity(): ContextCommunityFragment {
    // When the community is set to disallow anon access, and the user is not
    // authenticated, then we provide a stub of a community object
    if (!canAccessCommunity()) {
      return {
        id: null,
        title: null,
        displayId: null,
        nodeType: 'community',
        userContext: { canUpdateNode: false },
        seoProperties: {
          customOGSiteName: '',
          appendTopicUidInHead: false,
          linkCommentToTimeStamp: true,
          lowercasePath: true
        }
      };
    }
    return appContextData?.community ?? null;
  }

  const community = getCommunity();
  const authUser = self ?? null;
  const contextMessage = urlMessageData?.message ?? null;
  const contextNode = urlNodeData?.coreNode ?? null;
  const contextUser = urlUserData?.user ?? null;

  function getErrorTextKeyAndValues(): { key?: FormatMessageKey; values?: FormatMessageValues } {
    if (tenant?.enabled === false || !community) {
      return { key: 'noCommunity' };
    }

    if (!authUser) {
      return { key: 'noUser' };
    }

    const nodeIdFromUrl = getNodeIdFromUrl(router);

    if (!contextNode && nodeIdFromUrl) {
      return { key: 'noNode', values: { nodeId: nodeIdFromUrl } };
    }

    const messageFromUrl =
      router.getPathParams<MessagePagesAndParams>()[EndUserPathParams.MESSAGE_ID];

    if (!contextMessage && messageFromUrl) {
      return { key: 'noMessage', values: { messageId: messageFromUrl } };
    }

    const userFromUrl = router.getPathParams<UserPageAndParams>()[EndUserPathParams.USER_ID];

    if (!contextUser && router.getPathParams<UserPageAndParams>()[EndUserPathParams.USER_ID]) {
      return { key: 'noUser', values: { userId: userFromUrl } };
    }

    return {};
  }

  const pageEntities = [];
  if (contextNode?.id) {
    pageEntities.push(contextNode.id);
  }
  if (contextMessage?.id) {
    pageEntities.push(contextMessage.id);
  }
  if (contextUser?.id) {
    pageEntities.push(contextUser.id);
  }

  const pageTemplateContext: PageContext = {
    name: router.getCurrentPageName(),
    props: {},
    url: UrlBuilder.fromUrl(tenant.baseUrl).addPath(router.path).build(),
    entities: pageEntities
  };

  if (urlNodeError) {
    log.debug('Error fetching node from URL: %O', urlNodeError);
  }

  if (urlMessageError) {
    log.debug('Error fetching message from URL: %O', urlMessageError);
  }

  if (urlUserError) {
    log.debug('Error fetching user from URL: %O', urlUserError);
  }

  if (appContextError) {
    log.debug('Error fetching app context: %O', appContextError);
  }

  const context: AppContextInterface = {
    community,
    authUser,
    contextNode: (contextNode ?? contextMessage?.board ?? community) as ContextNodeFragment,
    contextMessage,
    contextUser,
    componentsLastModified,
    pagesLastModified,
    pageScriptsLastModified,
    quiltLastModified,
    quiltWrapperLastModified,
    textLastModified,
    entityDefinitionsLastModified,
    fieldDefinitionsLastModified,
    themeInfo: {
      id: theme?.result?.id ?? DEFAULT_THEME_ID,
      lastModified: themeLastModified
    },
    canAccess,
    canRegister,
    isCrawler,
    pageTemplateContext
  };

  log.trace('AppContext is %O', context);

  const { key: errorKey, values: errorValues } = getErrorTextKeyAndValues();

  return (
    <AppContext.Provider value={context}>
      <RedirectionWrapper pageParams={pageParamData}>
        {appContextError && <ApolloErrorAlert error={appContextError} />}
        {urlNodeError && <ApolloErrorAlert error={urlNodeError} />}
        {urlMessageError && <ApolloErrorAlert error={urlMessageError} />}
        {urlUserError && <ApolloErrorAlert error={urlUserError} />}
        {errorKey === undefined ? (
          children
        ) : (
          <CachedThemeContextProvider>
            <PageError statusCode={404} title={formatMessage(errorKey, errorValues)} />
          </CachedThemeContextProvider>
        )}
      </RedirectionWrapper>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
