import type {
  BlogPoliciesFragment,
  BoardPoliciesFragment,
  CategoryPoliciesFragment,
  CommunityPoliciesFragment,
  ForumPoliciesFragment,
  GroupHubPoliciesFragment,
  IdeaPoliciesFragment,
  NodePoliciesFragment,
  OccasionPoliciesFragment,
  TkbPoliciesFragment
} from '@aurora/shared-generated/types/graphql-types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';

/**
 * User defined type guard for working with policies on Blog nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isBlogPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is BlogPoliciesFragment {
  return nodePoliciesFragment && 'blogPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Tkb nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isTkbPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is TkbPoliciesFragment {
  return nodePoliciesFragment && 'tkbPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Forum nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isForumPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is ForumPoliciesFragment {
  return nodePoliciesFragment && 'forumPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Idea nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isIdeaPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is IdeaPoliciesFragment {
  return nodePoliciesFragment && 'ideaPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Occasion nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isOccasionPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is OccasionPoliciesFragment {
  return nodePoliciesFragment && 'occasionPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Board nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isBoardPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is BoardPoliciesFragment {
  return nodePoliciesFragment && 'boardPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Category nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isCategoryPolicy(
  nodePoliciesFragment: NodePoliciesFragment
): nodePoliciesFragment is CategoryPoliciesFragment {
  return nodePoliciesFragment && 'categoryPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on GroupHub nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isGroupHubPolicy(
  nodePoliciesFragment
): nodePoliciesFragment is GroupHubPoliciesFragment {
  return nodePoliciesFragment && 'grouphubPolicies' in nodePoliciesFragment;
}

/**
 * User defined type guard for working with policies on Community nodes
 *
 * @param nodePoliciesFragment the NodePoliciesFragment contained in the NodePoliciesQuery result
 */
export function isCommunityPolicy(
  nodePoliciesFragment
): nodePoliciesFragment is CommunityPoliciesFragment {
  return nodePoliciesFragment && 'communityPolicies' in nodePoliciesFragment;
}

/**
 * Whether the current user may read the provided node
 * @param node the node
 */
export function canReadNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canReadNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canReadNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canReadNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canReadNode);
  }

  return false;
}

/**
 * Whether the current user may update the provided node
 *
 * @param node the node
 */
export function canUpdateNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canUpdateNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canUpdateNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canUpdateNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canUpdateNode);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canUpdateNode);
  }
  return false;
}

/**
 * Whether the current user may delete the provided node
 *
 * @param node the node
 */
export function canDeleteNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canDeleteNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canDeleteNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canDeleteNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canDeleteNode);
  }

  return false;
}

/**
 * Whether the current user may move the provided node
 *
 * @param node the node
 */
export function canMoveNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canMoveNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canMoveNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canMoveNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canMoveNode);
  }

  return false;
}

/**
 * Whether the current user may view roles for the provided node
 *
 * @param node the node
 */
export function canViewRolesForNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canViewRoles);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewRoles);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewRoles);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canViewRoles);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewRoles);
  }

  return false;
}

/**
 * Whether the current user may view roles for the provided node
 *
 * @param node the node
 */
export function canViewUserRolesForNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canViewUserRoles);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewUserRoles);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewUserRoles);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canViewUserRoles);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewUserRoles);
  }

  return false;
}

/**
 * Whether the current user may create roles
 *
 * @param node the node
 */
export function canCreateRolesForNode(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canCreateRoles);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canCreateRoles);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canCreateRoles);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canCreateRoles);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canCreateRoles);
  }

  return false;
}

/**
 * Whether the current user may bulk import users to a role
 *
 * @param node the node
 */
export function canBulkImportUserToRole(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canBulkImportUserRoles);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canBulkImportUserRoles);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canBulkImportUserRoles);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canBulkImportUserRoles);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canBulkImportUserRoles);
  }

  return false;
}

/**
 * Whether the current user can administrate permissions
 *
 * @param node the node
 */
export function canAdminPermissions(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canAdminPermissions);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canAdminPermissions);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canAdminPermissions);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canAdminPermissions);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAdminPermissions);
  }

  return false;
}

/**
 * Whether the current user may access audit logs
 *
 * @param node the node
 */
export function canAccessAuditLogs(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAccessAuditLogs);
  }

  return false;
}

/**
 * Whether the current user may manage featured widget for the provided node
 *
 * @param node the node
 */
export function canManageFeaturedWidget(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canManageFeaturedWidget);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canManageFeaturedWidget);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canManageFeaturedWidget);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canManageFeaturedWidget);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canManageFeaturedWidget);
  }

  return false;
}

/**
 * Whether the current user may update featured widget for the provided node
 *
 * @param node the node
 */
export function canUpdateFeaturedWidget(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canUpdateFeaturedWidget);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canUpdateFeaturedWidget);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canUpdateFeaturedWidget);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canUpdateFeaturedWidget);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canUpdateFeaturedWidget);
  }

  return false;
}

/**
 * Whether the current user can see all Drafts in this node
 *
 * @param node the node
 */
export function canViewAllDraftsBlog(node: NodePoliciesFragment): boolean {
  if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canViewAllDraftsBlog);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewAllDraftsBlog);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewAllDraftsBlog);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewAllDraftsBlog);
  }

  return false;
}

/**
 * Whether the current user can see all Drafts in this node
 *
 * @param node the node
 */
export function canViewAllDraftsTkb(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canViewAllDraftsTkb);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewAllDraftsTkb);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewAllDraftsTkb);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewAllDraftsTkb);
  }

  return false;
}

/**
 * Whether the current user can manage article in this node
 *
 * @param node the node
 */
export function canManageArticleBlog(node: NodePoliciesFragment): boolean {
  if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canManageArticleBlog);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canManageArticleBlog);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canManageArticleBlog);
  }

  return false;
}

/**
 * Whether the current user can manage article in this node
 *
 * @param node the node
 */
export function canManageArticleTkb(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canManageArticleTkb);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canManageArticleTkb);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canManageArticleTkb);
  }

  return false;
}

/**
 * Whether the current user can access and manage the spam dashboard
 *
 * @param node the node
 */
export function canViewSpamDashBoard(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canViewSpamDashBoard);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewSpamDashBoard);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewSpamDashBoard);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewSpamDashBoard);
  }

  return false;
}

/**
 * Whether the calling user can access moderation manager
 *
 * @param node the node
 */
export function canModerateNode(node: NodePoliciesFragment): boolean {
  if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canModerateNode);
  } else if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canModerateNode);
  } else if (isOccasionPolicy(node)) {
    return checkPolicy(node.occasionPolicies.canModerateNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canModerateNode);
  } else if (isForumPolicy(node)) {
    return checkPolicy(node.forumPolicies.canModerateNode);
  } else if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canModerateNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canModerateNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canModerateNode);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canModerateNode);
  }

  return false;
}

/**
 * Whether the current user can start a blog article in this node if it's a blog node
 *
 * @param node the node
 */
export function canStartArticleBlog(node: NodePoliciesFragment): boolean {
  if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canStartArticleBlog);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canStartArticleBlog);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canStartArticleBlog);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canStartArticleBlog);
  }

  return false;
}

/**
 * Whether the current user can start a tkb article in this node if it's a tkb node
 *
 * @param node the node
 */
export function canStartArticleTkb(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canStartOrEditArticleTkb);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canStartOrEditArticleTkb);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canStartOrEditArticleTkb);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canStartOrEditArticleTkb);
  }
  return false;
}

/**
 * Whether the current user can manage abuse reported content
 * @param node the node
 */
export function canManageAbuseContent(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node?.boardPolicies?.canManageAbuseContent);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node?.categoryPolicies?.canManageAbuseContent);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node?.grouphubPolicies?.canManageAbuseContent);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node?.communityPolicies?.canManageAbuseContent);
  }
  return false;
}

/**
 * Whether the notification can be sent for message action or not
 * @param node the node
 */
export function canSendNotificationForMessageAction(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node?.boardPolicies?.canSendNotificationForMessageAction);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node?.categoryPolicies?.canSendNotificationForMessageAction);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node?.grouphubPolicies?.canSendNotificationForMessageAction);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node?.communityPolicies?.canSendNotificationForMessageAction);
  }
  return false;
}

/**
 * Whether the current user can update this node in admin settings or not
 *
 * @param node the node
 */
export function canAdminNode(node: NodePoliciesFragment): boolean {
  if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canAdminNode);
  } else if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canAdminNode);
  } else if (isForumPolicy(node)) {
    return checkPolicy(node.forumPolicies.canAdminNode);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canAdminNode);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canAdminNode);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAdminNode);
  } else if (isIdeaPolicy(node)) {
    return checkPolicy(node.ideaPolicies.canAdminNode);
  } else if (isOccasionPolicy(node)) {
    return checkPolicy(node.occasionPolicies.canAdminNode);
  }

  return false;
}

/**
 * Whether the current user can view analytics pages for the provided node
 *
 * @param node the node
 */
export function canViewAnalytics(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewAnalytics);
  }
  return false;
}

/**
 * Whether the current user has advance analytics role
 *
 * @param node the node
 */
export function isAdvancedAnalyticsUser(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAdvancedAnalyticsUser);
  }
  return false;
}

/**
 * Whether the current user can manage user at community level
 *
 * @param node the node
 */
export function canManageUsers(node: CommunityPoliciesFragment | NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canManageUsers);
  }
  return false;
}

/**
 * Whether the current user can access page builder pages for the provided node
 *
 * @param node the node
 */
export function canAccessPageBuilder(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAccessPageBuilder);
  }
  return false;
}

/**
 * Whether the current user can send private messages.
 * @param node the node
 */
export function canSendPrivateMessages(node: NodePoliciesFragment | null): boolean {
  return (
    node && isCommunityPolicy(node) && checkPolicy(node.communityPolicies.canSendPrivateMessages)
  );
}

/**
 * Whether the current user can manage redirect rules
 *
 * @param node the node
 */
export function canManageRedirectRules(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canManageRedirectRules);
  }
  return false;
}

/**
 * Whether the current user can manage zoom integration
 *
 * @param node the node
 */
export function canIntegrateZoom(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canIntegrateZoom);
  }
  return false;
}

/**
 * Whether the current user can access the salesforce case portal
 *
 * @param node the node
 */
export function canViewSalesforceCasePortal(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canReadReplyOwnCases);
  }
  return false;
}

/**
 * Whether the current user can create a case
 *
 * @param node the node
 */
export function canCreateSalesforceCase(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canCreateSupportCase);
  }
  return false;
}

/**
 * Whether the current user can close a case
 *
 * @param node the node
 */
export function canCloseSalesforceCase(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canCloseOwnCase);
  }
  return false;
}

/**
 * Whether the user has permission to add images on posts
 *
 * @param node
 */
export function canAddImagesOnPosts(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canAddImagesOnPosts);
  }
  return false;
}

/**
 * Whether the user has permission to add videos
 * @param node
 */
export function canAddVideos(node: NodePoliciesFragment): boolean {
  if (isBoardPolicy(node)) {
    return checkPolicy(node.boardPolicies.canAddVideos);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canAddVideos);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canAddVideos);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAddVideos);
  }
  return false;
}

/**
 * Whether to use the report abuse DSA features or not
 *
 * @param node the node
 */
export function canReportAbuseDSA(node: NodePoliciesFragment) {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canReportAbuseDSA);
  }
  return false;
}

/**
 * Whether the current user can edit all occasions
 *
 * @param node the node
 */
export function canSendExternalOccasionInvites(node: NodePoliciesFragment): boolean {
  if (isOccasionPolicy(node)) {
    return checkPolicy(node.occasionPolicies.canSendExternalOccasionInvites);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canSendExternalOccasionInvites);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canSendExternalOccasionInvites);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canSendExternalOccasionInvites);
  }
  return false;
}

/**
 * Whether the current user can edit all occasions
 *
 * @param node the node
 */
export function canSendOccasionInvites(node: NodePoliciesFragment): boolean {
  if (isOccasionPolicy(node)) {
    return checkPolicy(node.occasionPolicies.canSendOccasionInvites);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canSendOccasionInvites);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canSendOccasionInvites);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canSendOccasionInvites);
  }
  return false;
}

/**
 * Whether the current user can escalate any forum message
 *
 * @param node the node
 */
export function canEscalateForumMessage(node: NodePoliciesFragment) {
  if (isForumPolicy(node)) {
    return checkPolicy(node.forumPolicies.canEscalateMessage);
  }
  return false;
}

/**
 * Whether the current user can escalate own forum message
 *
 * @param node the node
 */
export function canEscalateOwnForumMessage(node: NodePoliciesFragment) {
  if (isForumPolicy(node)) {
    return checkPolicy(node.forumPolicies.canEscalateOwnMessage);
  }
  return false;
}

/**
 * Whether the current user can view escalations
 *
 * @param node the node
 */
export function canViewEscalations(node: NodePoliciesFragment) {
  if (isForumPolicy(node)) {
    return checkPolicy(node.forumPolicies.canViewEscalations);
  }
  return false;
}

/**
 * Whether the calling user can access localized categories page
 *
 * @param node the node
 */
export function canAdminLocalizedCategories(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAccessLocalizedCategories);
  }
  return false;
}

/**
 * Whether the calling user cannot delete a node because it is associated to a locale or not
 *
 * @param node the node
 */
export function isLocaleCategoryAssociated(node: NodePoliciesFragment): boolean {
  if (isCategoryPolicy(node)) {
    const policyResult = node.categoryPolicies.canDeleteNode;
    if (policyResult && policyResult.failureReason !== null) {
      return policyResult.failureReason.key.includes('localizedCategoryAssociated');
    }
  }
  return false;
}

/**
 * Whether the calling user can admin badges
 *
 * @param node the node
 */
export function canAdminBadges(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canAdminBadges);
  }
  return false;
}

/**
 * Whether the calling user can revoke badges
 *
 * @param node the node
 */
export function canRevokeBadges(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canRevokeBadges);
  }
  return false;
}

/**
 * Whether the current user manage guides on community
 *
 * @param node the community node
 */
export function canManageGuidesOnCommunity(node: NodePoliciesFragment): boolean {
  if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canManageGuidesOnCommunity);
  }
  return false;
}

/**
 * Whether the current user manage guides for a kb board
 *
 * @param node the kb board
 */
export function canManageGuides(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canManageGuides);
  }
  return false;
}

export function canViewDrafts(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canViewDrafts);
  } else if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canViewDrafts);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canViewDrafts);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canViewDrafts);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canViewDrafts);
  }

  return false;
}

export function canManageDrafts(node: NodePoliciesFragment): boolean {
  if (isTkbPolicy(node)) {
    return checkPolicy(node.tkbPolicies.canManageDrafts);
  } else if (isBlogPolicy(node)) {
    return checkPolicy(node.blogPolicies.canManageDrafts);
  } else if (isCategoryPolicy(node)) {
    return checkPolicy(node.categoryPolicies.canManageDrafts);
  } else if (isGroupHubPolicy(node)) {
    return checkPolicy(node.grouphubPolicies.canManageDrafts);
  } else if (isCommunityPolicy(node)) {
    return checkPolicy(node.communityPolicies.canManageDrafts);
  }

  return false;
}
