import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, useClassNameMapper } from 'react-bootstrap';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { SharedComponent } from '../../../enums';
import { formBypassOptions } from '../../../helpers/form/FormHelper/FormBypassHelper';
import { offsetPopperConfig } from '../../../helpers/ui/PopperJsHelper';
import type { CorrectedOverlayTriggerRenderProps } from '../../../types/react-bootstrap/CorrectedOverlayTriggerRenderProps';
import CustomValidationChecklist from '../../common/CustomValidationChecklist/CustomValidationChecklist';
import useIsWide from '../../useIsWide';
import { FormFieldVariant } from '../enums';
import PasswordField from '../PasswordField/PasswordField';
import type { BasePasswordFieldSpec, PasswordFieldSpec } from '../PasswordField/types';
import type { FormFieldProps } from '../types';
import localStyles from './NewPasswordField.module.pcss';
import useNewPasswordCustomValidations from './useNewPasswordCustomValidations';
import useTranslation from '../../useTranslation';

export type NewPasswordFieldSpec<
  NameT extends FieldPath<DataT>,
  DataT extends FieldValues
> = BasePasswordFieldSpec<NameT, DataT, FormFieldVariant.NEW_PASSWORD>;

/**
 * A wrapper around PasswordField that also handles rendering and validating password requirements
 * @author Rosalyn Rowe
 */
const NewPasswordField = <NameT extends FieldPath<DataT>, DataT extends FieldValues>(
  props: FormFieldProps<NameT, DataT, NewPasswordFieldSpec<NameT, DataT>>
): React.ReactElement => {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.NEW_PASSWORD_FIELD
  );
  const { bypassFormValidation } = formBypassOptions();
  const cx = useClassNameMapper(localStyles);
  const isWide = useIsWide();
  const { fieldSpec, formMethods } = props;

  const {
    formState: { isSubmitted },
    getValues,
    trigger
  } = formMethods;

  const { name, usernameName, username: specUsername } = fieldSpec;
  const username = usernameName ? getValues(usernameName) : specUsername;

  /** The value on the form seems to only update onBlur.. if we track it here we can know what it is after each keystroke, to help with real time validation */
  const [currentValue, setCurrentValue] = useState<string>(null);

  /**
   * react-hook-form tracks if a form/field is 'dirty' (is currently different than the default)
   * or 'touched' (has been interacted with at all, including a focus/blur cycle),
   * but we want to track if the user has ever actually *typed* in the field
   */
  const [enableTriggerOnBlur, setEnableTriggerOnBlur] = useState<boolean>(false);

  /** We don't want to trigger immediately, only after validations have already been run once */
  const [enableTriggerOnUsernameChanged, setEnableTriggerOnUsernameChanged] =
    useState<boolean>(false);

  /**
   * We need to manually trigger when the username changes, because it may change the validity of the password
   */
  useEffect(() => {
    if (enableTriggerOnUsernameChanged) {
      trigger(name);
    }
  }, [enableTriggerOnUsernameChanged, name, trigger, username]);

  const getCustomValidations = useNewPasswordCustomValidations<NameT, DataT>();

  if (textLoading) {
    return null;
  }

  const customValidations = getCustomValidations({
    currentPassword: currentValue,
    currentUsername: username,
    fieldSpec
  });

  const popover = (
    <Popover id="password-popover" className={cx('lia-popover')}>
      <Popover.Title className={cx('lia-popover-header')}>
        {formatMessage('popoverTitle')}
      </Popover.Title>
      <Popover.Content>
        <CustomValidationChecklist
          componentId={SharedComponent.NEW_PASSWORD_FIELD}
          customValidations={customValidations}
        />
      </Popover.Content>
    </Popover>
  );

  const passwordFieldProps: FormFieldProps<NameT, DataT, PasswordFieldSpec<NameT, DataT>> = {
    ...props,
    fieldSpec: {
      ...props.fieldSpec,
      fieldVariant: FormFieldVariant.PASSWORD,
      onBlur: () => {
        // No point in wasting cycles, it will revalidate on every change
        if (isSubmitted) {
          return;
        }

        /**
         * Revalidates when this field is clicked away from -- it's an exception to the mode/reValidateMode on the form
         * Should only occur if user has attempted to enter a password.
         */
        if (enableTriggerOnBlur) {
          trigger(name);
        }
      },
      onChange: event => {
        setEnableTriggerOnBlur(true);
        setCurrentValue((event.target as HTMLInputElement).value);
      },
      validations: {
        ...props.fieldSpec.validations,
        validate: {
          ...props.fieldSpec.validations.validate,
          validPassword: value => {
            setEnableTriggerOnUsernameChanged(true);

            /*
             * This runs before onChange so it needs to get a fresh instance of customValidations
             * Does not seem to impact performance much but will need to re-evaluate if it gets slow
             */
            const freshValidations = getCustomValidations({
              currentPassword: value,
              currentUsername: username,
              fieldSpec
            });
            return freshValidations.every(validation => !validation.hasError());
          }
        }
      }
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  if (bypassFormValidation) {
    return <PasswordField {...passwordFieldProps}></PasswordField>;
  }

  return (
    <OverlayTrigger
      overlay={popover}
      placement={isWide ? 'left' : 'bottom'}
      trigger="focus"
      popperConfig={offsetPopperConfig(0, 10)}
    >
      {({ ref, ...triggerHandler }) => (
        <PasswordField
          overlayRef={ref as React.MutableRefObject<HTMLInputElement>}
          triggerHandler={triggerHandler as CorrectedOverlayTriggerRenderProps}
          {...passwordFieldProps}
        ></PasswordField>
      )}
    </OverlayTrigger>
  );
};

export default NewPasswordField;
