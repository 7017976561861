import type {
  BoardPageParams,
  BoardPages,
  MessageEditPages,
  MessagePages,
  MessagePostPages,
  MessageReplyPages
} from '@aurora/shared-client/routes/endUserRoutes';
import type {
  Board,
  Form as FormSchema
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  ConversationStyle
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import type { MessageEditorFormData } from '../../components/messages';
import defaultMessageFormSchema from '../../components/messages/MessageEditorForm/MessageEditorForm.form.json';
import occasionMessageFormSchema from '../../components/messages/MessageEditorForm/MessageEditorOccasionForm.form.json';
import { MessageEditorFormAction, MessageEditorFormField } from '../../types/enums';

/**
 * @author Sravan Reddy
 *
 * Helper for working with Conversation Styles.
 */
interface ConversationStyleBehavior {
  /**
   * The route name for the board page.
   */
  boardRoute: BoardPages;
  /**
   * The route name for the message page.
   */
  messagePage: MessagePages;
  /**
   * The route name for the message reply page.
   */
  messageReplyPage: MessageReplyPages;
  /**
   * The route name for the message post page.
   */
  messagePostPage: MessagePostPages;
  /**
   * The route name for the message edit page.
   */
  messageEditPage: MessageEditPages;
  /**
   * The list of fields used in the message editor form.
   */
  messageEditorFormFields: (contentWorkflowEnabled?: boolean) => Array<keyof MessageEditorFormData>;
  /**
   * The list of actions used in the message editor form.
   */
  messageEditorFormActions: (messageState?: ContentWorkflowState) => Array<MessageEditorFormAction>;
  /**
   * The flag that determines whether content workflow is supported for the node type.
   */
  isContentWorkflowSupported: boolean;
  /**
   * The flag to use wide styling for the Conversation type.
   */
  useMessageViewWidePadding: boolean;
  /**
   * Flag which determines whether MessageAuthorBio component should be added to the topic MessageView.
   */
  useAuthorBio: boolean;
  /**
   * Flag to determine if a given discussion style has the ability to add seo content.
   */
  messageEditorSeoEnabled: (seoEnabled?: boolean) => boolean;

  /**
   * Flag to determine if a given discussion style has the ability to post anonymously.
   */
  isAnonymousPostingSupported: boolean;

  /**
   * Flag to determine if a given discussion style has the ability to post with an unverified email.
   */
  isUnverifiedEmailPostingSupporting: boolean;
  /**
   * Flag to determine if the form should be full page wide.
   */
  useMessageViewFullPage: boolean;
  /**
   * The editor form schema specific for a conversation style.
   */
  formSchema: FormSchema;
  /**
   * The form ID specific for a conversation style.
   */
  formId: string;
}

const log = getLog(module);

const defaultFields: Array<keyof MessageEditorFormData> = [
  MessageEditorFormField.SUBJECT,
  MessageEditorFormField.BODY,
  MessageEditorFormField.ATTACHMENTS,
  MessageEditorFormField.TAGS,
  MessageEditorFormField.CANONICAL_URL,
  MessageEditorFormField.SEO_TITLE,
  MessageEditorFormField.SEO_DESCRIPTION,
  MessageEditorFormField.CUSTOM_FIELDS,
  MessageEditorFormField.NOTIFY_AUTHOR,
  MessageEditorFormField.NOTIFY_PARTICIPANTS,
  MessageEditorFormField.EDIT_REASON
];

const defaultActions: Array<MessageEditorFormAction> = [
  MessageEditorFormAction.PUBLISH,
  MessageEditorFormAction.CANCEL
];

const contentWorkflowDisabledActions: Array<MessageEditorFormAction> = [
  MessageEditorFormAction.PUBLISH,
  MessageEditorFormAction.DRAFT,
  MessageEditorFormAction.CANCEL
];

const baseConversationStyleBehavior: ConversationStyleBehavior = {
  boardRoute: EndUserPages.ForumBoardPage,
  messagePage: EndUserPages.ForumMessagePage,
  messagePostPage: EndUserPages.ForumPostPage,
  messageReplyPage: EndUserPages.ForumReplyPage,
  messageEditPage: EndUserPages.ForumEditPage,
  messageEditorFormFields: () => defaultFields,
  messageEditorFormActions: () => defaultActions,
  isContentWorkflowSupported: false,
  useMessageViewWidePadding: false,
  useMessageViewFullPage: false,
  useAuthorBio: false,
  messageEditorSeoEnabled: () => false,
  isAnonymousPostingSupported: false,
  isUnverifiedEmailPostingSupporting: false,
  formSchema: defaultMessageFormSchema,
  formId: 'MessageEditorForm'
};

const conversationStyleToBehaviorMap: Record<
  ConversationStyle,
  Partial<ConversationStyleBehavior>
> = {
  [ConversationStyle.Blog]: {
    boardRoute: EndUserPages.BlogBoardPage,
    messagePage: EndUserPages.BlogMessagePage,
    messagePostPage: EndUserPages.BlogPostPage,
    messageReplyPage: EndUserPages.BlogReplyPage,
    messageEditPage: EndUserPages.BlogEditPage,
    messageEditorFormFields: contentWorkflowEnabled => {
      const fields = [
        MessageEditorFormField.COVERIMAGE_SUBJECT,
        MessageEditorFormField.TEASER,
        MessageEditorFormField.INTRODUCTION,
        MessageEditorFormField.BODY,
        MessageEditorFormField.ATTACHMENTS,
        MessageEditorFormField.COAUTHORS_LIST,
        MessageEditorFormField.AUTHOR_FOR_BLOG,
        MessageEditorFormField.TAGS,
        MessageEditorFormField.CANONICAL_URL,
        MessageEditorFormField.SEO_TITLE,
        MessageEditorFormField.SEO_DESCRIPTION,
        MessageEditorFormField.REVISION_NOTE,
        MessageEditorFormField.NOTIFY_AUTHOR,
        MessageEditorFormField.NOTIFY_PARTICIPANTS,
        MessageEditorFormField.EDIT_REASON
      ];
      if (contentWorkflowEnabled === false) {
        fields.push(MessageEditorFormField.SCHEDULE_PUBLISH_TIME);
      }
      return fields;
    },
    messageEditorFormActions: messageState => {
      let actions;
      if (messageState === ContentWorkflowState.ScheduledForPublish) {
        actions = [MessageEditorFormAction.SCHEDULE_PUBLISH, MessageEditorFormAction.CANCEL];
      } else {
        actions = contentWorkflowDisabledActions;
      }
      return actions;
    },
    isContentWorkflowSupported: true,
    useMessageViewWidePadding: true,
    useAuthorBio: true,
    messageEditorSeoEnabled: () => true
  },
  [ConversationStyle.Forum]: {
    messageEditorSeoEnabled: isSeoEnabled => isSeoEnabled || false,
    isAnonymousPostingSupported: true,
    isUnverifiedEmailPostingSupporting: true
  },
  [ConversationStyle.Qanda]: {},
  [ConversationStyle.Tkb]: {
    boardRoute: EndUserPages.TkbBoardPage,
    messagePage: EndUserPages.TkbMessagePage,
    messagePostPage: EndUserPages.TkbPostPage,
    messageReplyPage: EndUserPages.TkbReplyPage,
    messageEditPage: EndUserPages.TkbEditPage,
    messageEditorFormFields: contentWorkflowEnabled => {
      const fields = [
        ...defaultFields,
        MessageEditorFormField.TEASER,
        MessageEditorFormField.INTRODUCTION,
        MessageEditorFormField.REVISION_NOTE,
        MessageEditorFormField.CONTRIBUTORS_LIST
      ];
      if (contentWorkflowEnabled === false) {
        fields.push(MessageEditorFormField.SCHEDULE_PUBLISH_TIME);
      }
      return fields;
    },
    messageEditorFormActions: messageState => {
      let actions;
      if (messageState === ContentWorkflowState.ScheduledForPublish) {
        actions = [MessageEditorFormAction.SCHEDULE_PUBLISH, MessageEditorFormAction.CANCEL];
      } else {
        actions = contentWorkflowDisabledActions;
      }
      return actions;
    },
    isContentWorkflowSupported: true,
    useMessageViewWidePadding: true,
    useAuthorBio: true,
    messageEditorSeoEnabled: () => true
  },
  [ConversationStyle.Idea]: {
    boardRoute: EndUserPages.IdeaBoardPage,
    messagePostPage: EndUserPages.IdeaPostPage,
    messageEditPage: EndUserPages.IdeaEditPage,
    messagePage: EndUserPages.IdeaMessagePage,
    messageReplyPage: EndUserPages.IdeaReplyPage,
    messageEditorFormFields: () => {
      const fields = [...defaultFields];
      return fields;
    },
    messageEditorSeoEnabled: () => true
  },
  [ConversationStyle.Contest]: {},
  [ConversationStyle.Occasion]: {
    boardRoute: EndUserPages.EventBoardPage,
    messagePostPage: EndUserPages.EventPostPage,
    messageEditPage: EndUserPages.OccasionEditPage,
    messagePage: EndUserPages.OccasionMessagePage,
    messageReplyPage: EndUserPages.OccasionReplyPage,
    messageEditorFormFields: () => {
      const fields = [
        MessageEditorFormField.COVERIMAGE_SUBJECT,
        MessageEditorFormField.BODY,
        MessageEditorFormField.ATTACHMENTS,
        MessageEditorFormField.TAGS,
        MessageEditorFormField.FEATURED_GUESTS,
        MessageEditorFormField.OCCASION_TYPE,
        MessageEditorFormField.OCCASION_START_TIME,
        MessageEditorFormField.OCCASION_END_TIME,
        MessageEditorFormField.CANONICAL_URL,
        MessageEditorFormField.SEO_TITLE,
        MessageEditorFormField.SEO_DESCRIPTION,
        MessageEditorFormField.OCCASION_VENUE,
        MessageEditorFormField.OCCASION_LOCATION,
        MessageEditorFormField.OCCASION_LIVE_URL,
        MessageEditorFormField.NOTIFY_AUTHOR,
        MessageEditorFormField.NOTIFY_PARTICIPANTS,
        MessageEditorFormField.EDIT_REASON
      ];
      return fields;
    },
    messageEditorSeoEnabled: () => true,
    useMessageViewFullPage: true,
    formSchema: occasionMessageFormSchema,
    formId: 'MessageEditorOccasionForm'
  },
  [ConversationStyle.Group]: {}
};

// eslint-disable-next-line unicorn/no-static-only-class
export default class ConversationStyleBehaviorHelper {
  static getInstance(board: Pick<Board, 'conversationStyle'>): ConversationStyleBehavior {
    if (!board?.conversationStyle) {
      log.error('No conversation style specified on the board: %O', board);
      return baseConversationStyleBehavior;
    }
    const conversationStyleBehavior = conversationStyleToBehaviorMap[board.conversationStyle];
    if (!conversationStyleBehavior) {
      log.error('No conversation style behavior specified for %s', board.conversationStyle);
    }
    return {
      ...baseConversationStyleBehavior,
      ...conversationStyleBehavior
    };
  }

  /**
   * Returns all possible values for a given ConversationStyleBehavior property\
   *
   * @param property the property
   */
  static getPropertyValuesForAllConversationTypes<
    PropertyType extends keyof ConversationStyleBehavior
  >(property: PropertyType): Array<ConversationStyleBehavior[PropertyType]> {
    return Object.values(conversationStyleToBehaviorMap).map(
      (object: Partial<ConversationStyleBehavior>) => {
        const finalObject: ConversationStyleBehavior = {
          ...baseConversationStyleBehavior,
          ...object
        };

        return finalObject[property];
      }
    );
  }
}

/**
 *  Function to get the page and query params details based on the context node
 *
 * @param contextNode - Context node value
 */
export function getBoardViewAllPageAndQueryPathParams(contextNode): {
  page: BoardPages;
  pathParams: BoardPageParams;
} {
  let page;
  let pathParams;

  switch (contextNode.conversationStyle) {
    case ConversationStyle.Blog: {
      page = EndUserPages.BlogViewAllPostsPage;
      pathParams = {
        boardId: contextNode.displayId,
        categoryId: contextNode.parent.displayId
      };
      break;
    }
    case ConversationStyle.Forum: {
      page = EndUserPages.ForumViewAllTopicsPage;
      pathParams = {
        boardId: contextNode.displayId,
        categoryId: contextNode.parent.displayId
      };
      break;
    }
    case ConversationStyle.Tkb: {
      page = EndUserPages.TkbViewAllArticlesPage;
      pathParams = {
        boardId: contextNode.displayId,
        categoryId: contextNode.parent.displayId
      };
      break;
    }
    case ConversationStyle.Idea: {
      page = EndUserPages.IdeaViewAllIdeasPage;
      pathParams = {
        boardId: contextNode.displayId,
        categoryId: contextNode.parent.displayId
      };
      break;
    }
    case ConversationStyle.Occasion: {
      page = EndUserPages.AllOccasionsPage;
      pathParams = {
        boardId: contextNode.displayId,
        categoryId: contextNode.parent.displayId
      };
      break;
    }
    default: {
      return;
    }
  }

  return { page, pathParams };
}
