// eslint-disable-next-line import/prefer-default-export
export enum IconSize {
  /** Use this option for icon sizes that are not supported or not implemented here, then provide height/width on a class */
  FROM_CSS = 'fromCss',
  PX_12 = '12',
  PX_14 = '14',
  PX_16 = '16',
  PX_20 = '20',
  PX_24 = '24',
  PX_30 = '30',
  PX_40 = '40',
  PX_50 = '50',
  PX_60 = '60',
  PX_80 = '80',
  PX_120 = '120',
  PX_160 = '160'
}

export enum IconColor {
  GRAY_200 = '--lia-bs-gray-200',
  GRAY_300 = '--lia-bs-gray-300',
  GRAY_400 = '--lia-bs-gray-400',
  GRAY_500 = '--lia-bs-gray-500',
  GRAY_600 = '--lia-bs-gray-600',
  GRAY_700 = '--lia-bs-gray-700',
  GRAY_800 = '--lia-bs-gray-800',
  GRAY_900 = '--lia-bs-gray-900',
  BLACK = '--lia-bs-black',
  BODY_COLOR = '--lia-bs-body-color',
  WHITE = '--lia-bs-white',
  PRIMARY = '--lia-bs-primary',
  PRIMARY_DARK = '--lia-primary-dark',
  SECONDARY = '--lia-bs-secondary',
  INFO = '--lia-bs-info',
  SUCCESS = '--lia-bs-success',
  WARNING = '--lia-bs-warning',
  WARNING_DARK = '--lia-warning-dark',
  DANGER = '--lia-bs-danger',
  LIGHT = '--lia-bs-light',
  LINK_COLOR = '--lia-bs-link-color',
  DARK = '--lia-bs-dark',
  SYSTEM = '--lia-alert-system-color',
  FORUM = '--lia-forum-color',
  QUESTION = '--lia-qanda-color',
  SOLVED = '--lia-forum-solved-color',
  BLOG = '--lia-blog-color',
  KNOWLEDGE = '--lia-tkb-color',
  OCCASION = '--lia-occasion-color',
  IDEA = '--lia-idea-color',
  CONTEST = '--lia-contest-color',
  GROUP = '--lia-group-color',
  CATEGORY = '--lia-category-color',
  GROUPHUB = '--lia-grouphub-color',
  PRODUCT = '--lia-product-color',
  COMMUNITY = '--lia-community-color',
  IMAGE_PREVIEW_CONTROL = '--lia-img-preview-control-color',
  LOAD_TEXT = '--lia-load-text-color',
  NAV_CONTROLLER = '--lia-nav-controller-icon-color'
}
